import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase';
import { SecurityData } from './shared/SecurityData';
/*
const firebaseConfig = {
  apiKey: "AIzaSyDs0YHeCLG8xiOXSIhtvRXrJpkUKxgOCEI",
  authDomain: "geonet-me.firebaseapp.com",
  databaseURL: "https://apfg.firebaseio.com/",
  projectId: "geonet-me",
  storageBucket: "geonet-me.appspot.com",
  messagingSenderId: "275007142037",
  appId: "1:275007142037:web:3d66454176c629cfd3c39c",
  measurementId: "G-62ZPN57TNX" 
};*/
var firebaseConfig = {
  apiKey: "AIzaSyCPqR_MMQsivkpUOw7PGikMlVRaq8aPiGM",
  authDomain: "australianpfg.firebaseapp.com",
  databaseURL: "https://australianpfg.firebaseio.com",
  projectId: "australianpfg",
  storageBucket: "australianpfg.appspot.com",
  messagingSenderId: "878981646396",
  appId: "1:878981646396:web:4d75c4233333d4b991db39",
  measurementId: "G-GR6ZCENE62"
};
const runBootstrap = async () => {
  const firebaseApp = firebase.initializeApp(firebaseConfig)
  let called = false;
  const unSubscribe = firebaseApp.auth().onAuthStateChanged((user: SecurityData.FirabaseAuthUser | any) => {
    if (called) {
      return;
    }
    if (user) {
      console.log(user)
      called = true;
      console.log("USER", user)
      ReactDOM.render(<App uid={user.uid} firebaseApp={firebaseApp} />, document.getElementById('root'));
      unSubscribe();
    } else {
      // console.log("masuk tidak ada user")
      console.error('user null. berarti login di sini');
      firebaseApp.auth().signInAnonymously()
        .then(d => {
          console.log('User data: ', d);
        }).catch(exc => {
          console.error('Gagal login, error: ', exc);
        });
    }
  });
}
runBootstrap()

serviceWorker.unregister();
