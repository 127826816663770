import React from "react"
import { Container, FormGroup, Label, Input, Col, Row } from "reactstrap"
import Switch from 'react-bootstrap-switch';
import { qualificationType } from "../shared/data-leads";

type HomeProps = {
    formData: qualificationType
    setFormData: (formdata: qualificationType) => any
}
export const Home = (props: HomeProps) => {
    const qualification = props.formData
    console.log("qualification", qualification)
    return (
        <Container>
            <p> { qualification.Name === "" ? "" : `Dear ${qualification.Name}`}, </p>
            <p>Congratulations on taking the first steps to securing the best loan for you. On average our clients save $1,000’s of dollars just by getting good loan product advice.</p>
            <p>Unlike the banks, Our brokers will find the right product for you and ensure you are always in front of the game and have the best rate and lowest fees.</p>
            <p>If you’re a first home buyer we can work out exactly which government grants you qualify for simply by completing this quick 2 minute survey. With up to $40,000 in grants and rebates available its important to have someone protecting your best interest, and getting you into your own home quicker.</p>
            <p>While rates are at an all time low, now is the time to take action and secure your financial future now!</p>

            <p><strong>Reasons for Seeking Service?</strong></p>
            <FormGroup check className="form-check-radio">
                <Label check>
                    <Input
                        name="Reasons"
                        type="radio"
                        onChange={(x) => {
                            qualification.Reasons = 'Refinance'
                            props.setFormData(qualification)

                        }}
                        checked={qualification.Reasons === 'Refinance'}
                    ></Input>
                    <span className="form-check-sign"></span>
                    Refinance
                </Label>
            </FormGroup>
            <Row style={{ display: qualification.Reasons === "Refinance" ? '' : 'none' }}>
                <Col xs={1}>
                </Col>
                <Col xs={11}>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Bank </Label>
                        <Col xs="8">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(evt) => {
                                    const val = evt.target.value
                                    if (!qualification.Refinance) {
                                        qualification.Refinance = {}
                                    }
                                    qualification.Refinance.Bank = val
                                    props.setFormData(qualification)
                                }}
                                value={qualification.Refinance && qualification.Refinance.Bank ? qualification.Refinance.Bank : ""}
                            ></input>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Amount Owing </Label>
                        <Col xs="8">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(evt) => {
                                    const val = evt.target.value
                                    if (!qualification.Refinance) {
                                        qualification.Refinance = {}
                                    }
                                    qualification.Refinance["Amount Owing"] = val
                                    props.setFormData(qualification)
                                }}
                                value={qualification.Refinance && qualification.Refinance["Amount Owing"] ? qualification.Refinance["Amount Owing"] : ''}
                            ></input>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Value </Label>
                        <Col xs="8">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(evt) => {
                                    const val = evt.target.value
                                    if (!qualification.Refinance) {
                                        qualification.Refinance = {}
                                    }
                                    qualification.Refinance.Value = val
                                    props.setFormData(qualification)
                                }}
                                value={qualification.Refinance && qualification.Refinance.Value ? qualification.Refinance.Value : ''}
                            ></input>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Interest Rate  </Label>
                        <Col xs="8">
                            <input
                                className="form-control-plaintext"
                                id="staticEmail"
                                type="text"
                                onChange={(evt) => {
                                    const val = evt.target.value
                                    if (!qualification.Refinance) {
                                        qualification.Refinance = {}
                                    }
                                    qualification.Refinance["Interest Rate"] = val
                                    props.setFormData(qualification)
                                }}
                                value={qualification.Refinance && qualification.Refinance["Interest Rate"] ? qualification.Refinance["Interest Rate"] : ""}
                            ></input>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup check className="form-check-radio">
                <Label check>
                    <Input
                        name="Reasons"
                        type="radio"
                        onChange={() => {
                            qualification.Reasons = 'Refinance - Debt Consolidation'
                            props.setFormData(qualification)
                        }}
                        checked={qualification.Reasons === 'Refinance - Debt Consolidation'}
                    ></Input>
                    <span className="form-check-sign"></span>
                    Refinance - Debt Consolidation
                </Label>
            </FormGroup>
            <Row style={{ display: qualification.Reasons === "Refinance - Debt Consolidation" ? '' : 'none' }}>
                <Col xs={1}></Col>
                <Col xs={11}>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="7">  Value of debts to be refinanced </Label>
                        <Col xs="5">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(evt) => {
                                    const val = evt.target.value
                                    qualification["Refinance - Debt Consolidation"] = val
                                    props.setFormData(qualification)
                                }}
                                value={qualification["Refinance - Debt Consolidation"]}
                            ></input>
                        </Col>
                    </FormGroup>

                </Col>
            </Row>
            <FormGroup check className="form-check-radio">
                <Label check>
                    <Input
                        name="Reasons"
                        type="radio"
                        onChange={() => {
                            qualification.Reasons = 'Purchase'
                            props.setFormData(qualification)
                        }}
                        checked={qualification.Reasons === 'Purchase'}
                    ></Input>
                    <span className="form-check-sign"></span>
                    Purchase
                </Label>
            </FormGroup>
            <Row style={{ display: qualification.Reasons === "Purchase" ? '' : 'none' }}>
                <Col xs={1}></Col>
                <Col xs={11}>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="7"> Are you a first Home buyer? </Label>
                        <Col xs="5">
                            <Switch onChange={(x) => {
                                if (!qualification.Purchase) {
                                    qualification.Purchase = {}
                                }
                                qualification.Purchase["first Home buyer"] = x.value() ? "YES" : 'NO'
                                props.setFormData(qualification)
                            }}
                                value={qualification.Purchase!["first Home buyer"] === 'YES' ? true : false} onText={"YES"} offText={"NO"} onColor="warning" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="7"> Have you found a property? </Label>
                        <Col xs="5">
                            <Switch onChange={(x) => {
                                if (!qualification.Purchase) {
                                    qualification.Purchase = {}
                                }
                                qualification.Purchase["Have you found a property"] = x.value() ? "YES" : 'NO'
                                props.setFormData(qualification)
                            }
                            }
                                value={qualification.Purchase!["Have you found a property"] === 'YES' ? true : false} onText={"YES"} offText={"NO"} onColor="warning" />
                        </Col>
                    </FormGroup>
                    <FormGroup style={{ display: qualification.Purchase?.["Have you found a property"] === 'NO' ? '' : 'none' }} className="row">
                        <Label htmlFor="staticEmail" xs="7"> What suburb are you looking? </Label>
                        <Col xs="5">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(evt) => {
                                    const val = evt.target.value
                                    if (!qualification.Purchase) {
                                        qualification.Purchase = {}
                                    }
                                    qualification.Purchase["What suburb are you looking"] = val
                                    props.setFormData(qualification)
                                }}
                                value={qualification.Purchase && qualification.Purchase["What suburb are you looking"] ? qualification.Purchase["What suburb are you looking"] : ''}
                            ></input>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="7"> Purchase Price or Budget </Label>
                        <Col xs="5">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(evt) => {
                                    const val = evt.target.value
                                    if (!qualification.Purchase) {
                                        qualification.Purchase = {}
                                    }
                                    qualification.Purchase["Purchase Price or Budget"] = val
                                    props.setFormData(qualification)
                                }}
                                value={qualification.Purchase && qualification.Purchase["Purchase Price or Budget"] ? qualification.Purchase["Purchase Price or Budget"] : ''}
                            ></input>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup check className="form-check-radio">
                <Label check>
                    <Input
                        name="Reasons"
                        type="radio"
                        onChange={() => {
                            qualification.Reasons = 'Personal Loan'
                            props.setFormData(qualification)
                        }}
                        checked={qualification.Reasons === 'Personal Loan'}
                    ></Input>
                    <span className="form-check-sign"></span>
                    Personal Loan
                </Label>
            </FormGroup>
            <Row style={{ display: qualification.Reasons === "Personal Loan" ? '' : 'none' }}>
                <Col xs={1}></Col>
                <Col xs={11}>
                    <span>Purpose of loan</span>
                    <FormGroup check className="form-check-radio">
                        <Label check>
                            <Input
                                value="Debt consolidation"
                                name="Purposeofloan"
                                type="radio"
                                onClick={() => {
                                    if (!qualification["Personal Loan"]) {
                                        qualification["Personal Loan"] = {}
                                    }
                                    qualification["Personal Loan"]["Purpose of loan"] = "Debt consolidation"
                                    props.setFormData(qualification)
                                }}
                                checked={qualification["Personal Loan"] && qualification["Personal Loan"]["Purpose of loan"] === "Debt consolidation"}
                            ></Input>
                            <span className="form-check-sign"></span>
                            Debt consolidation
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio">
                        <Label check>
                            <Input
                                value="Vehicle purchase"
                                name="Purposeofloan"
                                type="radio"
                                onClick={() => {
                                    if (!qualification["Personal Loan"]) {
                                        qualification["Personal Loan"] = {}
                                    }
                                    qualification["Personal Loan"]["Purpose of loan"] = "Vehicle purchase"
                                    props.setFormData(qualification)
                                }}
                                checked={qualification["Personal Loan"] && qualification["Personal Loan"]["Purpose of loan"] === "Vehicle purchase"}
                            ></Input>
                            <span className="form-check-sign"></span>
                            Vehicle purchase
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio">
                        <Label check>
                            <Input
                                value="Holiday"
                                name="Purposeofloan"
                                type="radio"
                                onClick={() => {
                                    if (!qualification["Personal Loan"]) {
                                        qualification["Personal Loan"] = {}
                                    }
                                    qualification["Personal Loan"]["Purpose of loan"] = "Holiday"
                                    props.setFormData(qualification)
                                }}
                                checked={qualification["Personal Loan"] && qualification["Personal Loan"]["Purpose of loan"] === "Holiday"}
                            ></Input>
                            <span className="form-check-sign"></span>
                            Holiday
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio">
                        <Label check>
                            <Input
                                value="Home Improvements"
                                name="Purposeofloan"
                                type="radio"
                                onClick={() => {
                                    if (!qualification["Personal Loan"]) {
                                        qualification["Personal Loan"] = {}
                                    }
                                    qualification["Personal Loan"]["Purpose of loan"] = "Home Improvements"
                                    props.setFormData(qualification)
                                }}
                                checked={qualification["Personal Loan"] && qualification["Personal Loan"]["Purpose of loan"] === "Home Improvements"}
                            ></Input>
                            <span className="form-check-sign"></span>
                            Home Improvements
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio">
                        <Label check>
                            <Input
                                value="Other: Please elaborate"
                                name="Purposeofloan"
                                type="radio"
                                onClick={() => {
                                    if (!qualification["Personal Loan"]) {
                                        qualification["Personal Loan"] = {}
                                    }
                                    qualification["Personal Loan"]["Purpose of loan"] = "Other: Please elaborate"
                                    props.setFormData(qualification)
                                }}
                                checked={qualification["Personal Loan"] && qualification["Personal Loan"]["Purpose of loan"] === "Other: Please elaborate"}
                            ></Input>
                            <span className="form-check-sign"></span>
                            Other: Please elaborate
                        </Label>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="7"> Loan Amount required </Label>
                        <Col xs="5">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(evt) => {
                                    const val = evt.target.value
                                    if (!qualification["Personal Loan"]) {
                                        qualification["Personal Loan"] = {}
                                    }
                                    qualification["Personal Loan"]["Loan Amount required"] = val
                                    props.setFormData(qualification)
                                }}
                                value={qualification["Personal Loan"] && qualification["Personal Loan"]["Loan Amount required"] ? qualification["Personal Loan"]["Loan Amount required"] : ''}
                            ></input>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
        </Container>
    )
}